
import { CloudProvidersApi, Option } from './types';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';




export class CloudProvidersBackendClient implements CloudProvidersApi {
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
  }) {
    this.discoveryApi = options.discoveryApi;
  }

  private async handleResponse(response: Response): Promise<any> {
    if (!response.ok) {
      throw new Error();
    }
    return await response.json();
  }

  async getHealth(): Promise<{ status: string; }> {
    const url = `${await this.discoveryApi.getBaseUrl('cloud-providers')}/health`;

    const response = await fetch(url, {
      method: 'GET',
    });
    return await this.handleResponse(response);
  }
  
  async getOrganizations(params: any): Promise<Option[]> {
    console.log("--getOrganizations---")
    const url = new URL(`${await this.discoveryApi.getBaseUrl('cloud-providers')}/organizations`);
    const response = await fetch(url.href, {
      method: 'GET',
    });

    return await this.handleResponse(response);
  }

  async getProjects(params: any): Promise<Option[]> {
    const url = new URL(`${await this.discoveryApi.getBaseUrl('cloud-providers')}/projects`);
    console.log("params.organizationId", params.organizationId)
    if (params.organizationId != null) {
      var organizationId 
      if (typeof params.organizationId === 'object') {
        organizationId = params.organizationId.id
    
      }
      if (typeof params.organizationId === 'string') {
        organizationId = params.organizationId
      }
      if (params.organizationId.id != null) {
        url.searchParams.append("organizationId", organizationId);
      }
    } else {
      url.searchParams.append("organizationId", "");
    }

    const response = await fetch(url.href, {
      method: 'GET',
    });

    return await this.handleResponse(response);
  }
  async getNetworks(params: any): Promise<Option[]> {
    console.log("--getNetworks---")
    const url = new URL(`${await this.discoveryApi.getBaseUrl('cloud-providers')}/networks`);
    var projectId 

    var tipo =  params.projectId

    if (typeof params.projectId === 'object') {
      projectId = params.projectId.id
  
    }
    if (typeof params.projectId === 'string') {
      projectId = params.projectId
    }
    // console.log("params", params)
    

    url.searchParams.append("projectId", projectId);

    const response = await fetch(url.href, {
      method: 'GET',
    });

    return await this.handleResponse(response);
  }
  async getSubNetworks(params: any): Promise<Option[]> {
    // console.log("--getSubNetworks---")
    const url = new URL(`${await this.discoveryApi.getBaseUrl('cloud-providers')}/subnetworks`);
    var projectId
    var networkId

    if (typeof params.projectId === 'object') {
      projectId = params.projectId.id
    }
    if (typeof params.projectId === 'string') {
      projectId = params.projectId
    }
    if (typeof params.network === 'object') {
      networkId = params.network.name
    }
    if (typeof params.network === 'string') {
      networkId = params.network
    }

    url.searchParams.append("projectId", projectId);
    url.searchParams.append("networkId", networkId);

    const response = await fetch(url.href, {
      method: 'GET',
    });

    return await this.handleResponse(response);
  }

  async getGithubDirectories(params: any): Promise<Option[]> {

    const url = new URL(`${await this.discoveryApi.getBaseUrl('cloud-providers')}/github-directories`);

    url.searchParams.append("repoUrl", params.owner);

    const response = await fetch(url.href, {
      method: 'GET',
    });

    return await this.handleResponse(response);
  }

}
import { createApiFactory, createComponentExtension, createPlugin, createRoutableExtension, discoveryApiRef, identityApiRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { cloudProvidersApiRef } from './api';
import { CloudProvidersBackendClient } from './api/CloudProvidersBackendClient';

export const cloudProvidersPlugin = createPlugin({
  id: 'cloud-providers',
	apis: [
    createApiFactory({
      api: cloudProvidersApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
      },
      factory: ({ discoveryApi }) =>
        new CloudProvidersBackendClient({ discoveryApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const CloudProvidersPage = cloudProvidersPlugin.provide(
  createRoutableExtension({
    name: 'CloudProvidersPage',
    component: () =>
      import('./components/ExampleComponent').then(m => m.ExampleComponent),
    mountPoint: rootRouteRef,
  }),
);

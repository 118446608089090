// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FieldProps, FieldValidation, UiSchema } from '@rjsf/core';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { useCloudProvider, Resource } from '../../hooks/useCloudProvider';
import { Option } from '../../api/types';

function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

interface Param {
	formKey: string;
	reqKey: string;
	required: boolean;
}

const extractParams = (formContext: any, uiSchema: UiSchema) => {
	const options = uiSchema['ui:options']
	const params = (options?.params || []) as Param[]

	return params.reduce((acc, { formKey, reqKey }) => {
		const formdata = formContext?.formData || {}
		const value = formdata[formKey]

		if (value === undefined) {
			return acc;
		}

		return { ...acc, [reqKey]: value }
	}, {})
}

const canDoRequest = (isOpen: boolean, uiSchema: UiSchema, reqParams: object) => {
	const options = uiSchema['ui:options']
	const params = (options?.params || []) as Param[]

	if (!isOpen) {
		return false
	}

	for (const param of params) {
		if (param.required && !reqParams.hasOwnProperty(param.reqKey)) {
			return false;
		}
	}

	return true
}


const shouldClearValue = (formData: Option, uiSchema: UiSchema, reqParams: object) => {
	if (!formData) {
		return false
	}

	const options = uiSchema['ui:options']
	const params = (options?.params || []) as Param[]


	for (const param of params) {
		if (param.required && !reqParams.hasOwnProperty(param.reqKey)) {
			return true;
		}
	}



	return false
}

const Asynchronous = ({
	onChange,
	rawErrors,
	required,
	formData,
	formContext,
	uiSchema,
	...rest
}: FieldProps<Option>) => {

	// console.log({ uiSchema })
	let type = Resource.Network;

	if (uiSchema['ui:options']?.type === "organization") {
		type = Resource.Organization;
	}
	if (uiSchema['ui:options']?.type === "projectId") {
		type = Resource.Projects;
	}

	if (uiSchema['ui:options']?.type === "network") {
		type = Resource.Network;
	}
	if (uiSchema['ui:options']?.type === "subnetwork") {
		type = Resource.SubNetwork;
	}
	if (uiSchema['ui:options']?.type === "github-directories") {
		type = Resource.GithubDirectories;
	}


	const [open, setOpen] = React.useState(false);

	const reqParams = extractParams(formContext, uiSchema)
	const doRequest = canDoRequest(open, uiSchema, reqParams)
	const clearValue = shouldClearValue(formData, uiSchema, reqParams)

	const {
		loading,
		data: options,
	} = useCloudProvider(type, reqParams, doRequest)



	useEffect(() => {
		if (clearValue) {
			onChange(undefined)
		}
	}, [clearValue, onChange])

	return (
		<FormControl
			margin="normal"
			required={required}
			error={rawErrors?.length > 0 && !formData}
		>
			<InputLabel htmlFor="validateName" />
			<Autocomplete
				id="asynchronous-demo"
				style={{ width: 300 }}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				getOptionSelected={(option, value) => option.name === value.name}
				getOptionLabel={(option) => option.name || ""}
				options={options}
				loading={loading}
				onChange={(event, newValue) => {
					onChange(newValue);
				}}
				value={formData || null}
				renderInput={(params) => (
					<TextField
						{...params}
						label={rest.schema.title}
						variant="outlined"
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
			<FormHelperText id="entityName">
				{rest.schema.description}
			</FormHelperText>
		</FormControl>
	);
}

/*
This is a validation function that will run when the form is submitted.
You will get the value from the `onChange` handler before as the value here to make sure that the types are aligned\
*/

const validateAsynchronous = (
	value: string,
	validation: FieldValidation,
) => {

};

export {
	Asynchronous,
	validateAsynchronous,
}
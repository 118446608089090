import { useCallback, useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';

import { Option, cloudProvidersApiRef } from '../api/types';


enum Status {
	Idle = 1,
	Loading,
	Error,
	Ok,
}

interface State {
	data: Option[];
	status: Status;
}

const initialState: State = {
	data: [],
	status: Status.Idle,
}


export enum Resource {
	Organization = 1,
	Projects = 2,
	Network = 3,
	SubNetwork = 4,
	GithubDirectories= 5,
}

export const useCloudProvider = (resource: Resource, params: any, doRequest: boolean = true) => {
	const [state, setState] = useState<State>(initialState);


	const api = useApi(cloudProvidersApiRef);

	const getObjects = useCallback(
		async () => {
			let active = true;


			if (!doRequest) {
				return undefined;
			}

			let newState: State = {
				data: [],
				status: Status.Loading,
			}

			setState({
				data: [],
				status: Status.Loading,
			});

			try {
				// console.log(Resource.Projects, resource)
				
				let func = api.getOrganizations.bind(api)

				if (resource === Resource.Projects) {
					func = api.getProjects.bind(api)
				}
				if (resource === Resource.Network) {
					func = api.getNetworks.bind(api)
				}
				if (resource === Resource.SubNetwork) {
					func = api.getSubNetworks.bind(api)
				}
				if (resource === Resource.GithubDirectories) {
					func = api.getGithubDirectories.bind(api)
				}

				const res = await func(params);

				newState = {
					data: res,
					status: Status.Ok,
				}
			} catch (e) {

				newState = {
					data: [],
					status: Status.Error,
				}
			}

			if (active) {
				setState(newState);
			}

			return () => {
				active = false;
			};

		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[api, setState, doRequest, JSON.stringify(params)],
	);

	useEffect(() => {
		getObjects();
	}, [getObjects]);

	useEffect(() => {
		if (!doRequest) {
			setState({
				data: [],
				status: Status.Ok,
			});
		}
	}, [doRequest]);

	const loading = state.status === Status.Loading;
	const error = state.status === Status.Error;
	const idle = state.status === Status.Idle;
	const data = state.data;

	return {
		error,
		loading,
		idle,
		data,
	};
};
